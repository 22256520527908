<template>
    <ul class="sidebar__nav-list" v-if="data.length > 0">
        <nav-item v-for="item in data" :key="item.id" :data="item" />
    </ul>
</template>

<script>
    export default {
        name: 'NavList',
        props: ['data'],
        components: {
            NavItem: () => import('./NavItem')
        }
    }
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';

    .sidebar__nav {
        position: relative;
        &-list {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 0 10px;
            color: #fff;
            @media (max-width: 600px) {
                height: calc(100% - 60px);
            }
        }
    }
</style>
